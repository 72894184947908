.builtin-answer-load-ressource {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #00000012;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
/**
     recursive component info
*/
.titleLevel-1 {
	font-size: inherit;
	font-weight: 400;
	font-variant: all-small-caps;
	font-size: 16px;
}
.titleLevel-2 {
	font-weight: 400;
	font-size: initial;
	color: #656464;
	font-variant: all-small-caps;
}
.titleLevel {
	display: inline-block;
}
.titleAccordion {
	padding: 6px 12px;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 500;
	font-variant: all-small-caps;
	font-size: 1.1em;
	color: #585757;
}
.levelWrapper {
	margin-left: 0%;
}
.cockpitTabInfo .MuiAccordionDetails-root {
	padding: 0;
	/* background-color: #f9f9f9;*/
}
.levelWrapper-details {
	margin-left: 2%;
}
.levelWrapper-titleLevelCollapseIcon {
	display: contents;
}
.levelWrapper-titleLevelCollapseIconWrapper {
	float: right;
	display: flex;
}
.levelWrapper-titleLevelCollapseIconWrapper > button {
	display: contents;
	background: transparent;
}
.levelWrapper-details-titleWrapper {
	cursor: pointer;
	clear: right;
}
/* custom style collapse */
.levelWrapper-details .MuiCollapse-wrapper {
	display: block;
}
.detailsContent-label {
	margin-right: 1% !important;
	color: black !important;
	padding: 0 !important;
	margin: 0 !important;
	font-weight: normal !important;
	text-transform: none !important;
	display: inline-block !important;
	min-height: 0 !important;
	min-width: 0 !important;
	/* font-weight: 500;*/
	/* color: #696767;*/
}
.detailsContent-label:hover {
	background-color: rgba(0, 0, 0, 0.04) !important;
}
.cockpitTabInfo .MuiAccordionSummary-content,
.cockpitTabInfo .MuiAccordionSummary-content.Mui-expanded {
	margin: 0;
	/*  padding: 1%;*/
}
.cockpitTabInfo .MuiAccordionSummary-root.Mui-expanded {
	min-height: auto;
}
.cockpitTabInfo.MuiAccordion-root.Mui-expanded.MuiPaper-root.MuiAccordion-rounded {
	margin: 3px 0 !important;
}
.cockpitTabInfo .MuiCollapse-entered {
	clear: right;
}
.cockpitTabInfo .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.MuiIconButton-edgeEnd {
	padding-right: 0;
}
.cockpitTabInfo .MuiIconButton-root {
	padding: 0;
}
.detailsContent-data {
	font-weight: 400;
	vertical-align: sub;
}
.cockpitTabInfo .logoContainer .logoActions {
	object-fit: contain;
	height: 70%;
}
.logoContainer {
	display: flex;
	align-items: center;
}
.cockpitTabInfo .MuiAccordionSummary-root {
	align-items: inherit;
}
.btnShow {
	text-align: right;
}

.logo-channel {
	object-fit: contain;
}
