.subModule {
	width: 100%;
	height: 100%;
	padding: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.subModule:hover {
	cursor: pointer;
	background-color: #f5f5f5 !important;
}

.item-img p > img {
	max-width: 550px !important ;
}
.item-img div > img {
	max-width: 550px !important ;
	max-height: 550px !important ;
}

.item-img img {
	max-width: 100%;
}

.filter-container {
	z-index: 10000;
}

.av-source-colorised {
	border-radius: 5px;
	padding-left: 5px;
	padding-right: 5px;
	width: auto;
	margin-right: 4px;
	position: relative;
	font-weight: normal;
	background-color: wheat;
	color: #00000086;
	box-shadow: 0 3px 6px -3px rgb(130, 130, 130);
}

.av-source {
	border: 1px solid black;
	border-radius: 100%;
	padding: 2px;
	width: 20px;
	height: 20px;
	margin-right: 4px;
	position: relative;
	font-weight: normal;
}

.av-source div {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.se-customClass .se-tooltip-text {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	font-weight: 300;
}
