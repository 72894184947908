.emailCCElement,
.emailBCCElement {
	border-color: #666;
	color: #666;
	border-radius: 0.25rem;
	border: 1px solid;
	padding: 8px;
	font-size: 0.8rem;
	margin: 2px 0 !important;
}

.emailCCInput {
	margin-top: 16px !important;
	font-size: 0.8rem;
}

.attachmentList > button {
	width: 100%;
}

.editorMessageItem > .ql-container.ql-snow {
	min-height: 15px !important;
}

.PrivateNotchedOutline-root-137 {
	top: -7px !important;
}
