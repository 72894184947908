#\#\/properties\/code,
#\#\/properties\/assistantID,
#\#\/properties\/class,
#\#\/properties\/client,
#\#\/properties\/activeFaibrik{
    display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus{
    outline:none !important
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within{
    outline:none !important
}

.MuiDataGrid-selectedRowCount{
    display:none !important
}

.MuiDataGrid-root .MuiDataGrid-footer{
    justify-content: flex-end  !important;
    min-height: 6em !important;
}
.MuiDataGrid-root .MuiDataGrid-footerContainer {
    padding:2em !important;
    display:inherit !important
}
.MuiSnackbarContent-root{
    background-color:rgb(76, 89, 206) !important
}