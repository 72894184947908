.sun-editor-editable * {
	font-size: 16px;
	font-family: "Roboto";
	color: black;
	font-weight: normal;
}

.sun-editor-editable > p > strong {
	font-weight: bold !important;
}

body div.se-container > input {
	display: none;
}
