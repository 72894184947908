.classAccordeonTabInfo .MuiAccordionDetails-root {
	display: flex;
	padding: 0px 4px 4px !important;
}
.MuiAccordion-root.Mui-expanded {
	margin: 2px 0 !important;
}

.classAccordeonTabInfo .MuiAccordionSummary-content {
	margin: 0px 0 !important;
}

.classAccordeonTabInfo .MuiFormControl-marginDense {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.classAccordeonTabInfo .MuiInputBase-input.Mui-disabled {
	color: black !important;
}
.classAccordeonTabInfo .MuiAccordionSummary-root.Mui-expanded {
	min-height: 0px !important;
}
