.signatureEditor img {
	max-width: 200px;
	max-height: 200px;
}

.firstRowGrid {
	background-color: #00acc1;
	color: white;
	font-weight: bold;
}

.firstRowGrid:hover {
	background-color: #017d8d !important;
}

.firstRowGrid.MuiDataGrid-row.Mui-selected {
	background-color: #6bd1df !important;
}

.formDialog {
	padding-top: 80px;
	max-width: 340px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;
}

/* Input style */
.input-container {
	position: relative;
	display: flex;
	flex-direction: column;
}

.input-container:focus-within label {
	transform: translate(0, 12px) scale(0.8);
	color: #707070;
}

.input-container .filled {
	transform: translate(0, 12px) scale(0.8);
}

.input-container label {
	position: absolute;
	pointer-events: none;
	transform: translate(0, 23px) scale(1);
	transform-origin: top left;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	color: #616161;
	font-size: 16px;
	line-height: 1;
	left: 16px;
}

.input-container input {
	height: 64px;
	border-radius: 4px;
	border: none;
	padding: 24px 16px 4px 16px;
	font-size: 16px;
	line-height: 1;
	outline: none;
	box-shadow: none;
	transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container {
	box-shadow: 0 0 0 2px #d8d6d6;
	border-radius: 3px;
}

.errorText {
	color: red;
	font-size: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
	font-weight: bold;
}

.ulSquare {
	border: 1px solid #d8d6d6;
	border-radius: 5px;
	margin-top: 5px;
	padding: 15px 5px 15px 5px;
	font-size: 15px;
	max-height: 50vh;
	overflow-y: scroll;
}

.liElement {
	margin-top: 5px;
}

.selectList {
	width: 100%;
	font-size: 15x;
	padding: 10px;
	border: 1px solid #d8d6d6;
	border-radius: 5px;
}

.rowContactListHover:hover {
	background-color: #7c7c7c24;
}

.paginationRemovePadding div {
	margin-left: 0;
	padding-left: 0;
}

.paginationRemovePadding button {
	padding: 6px;
}

.paginationRemovePadding svg {
	margin: 0 !important;
}

.contact-phone-display input[disabled] {
	color: rgb(0, 0, 0, 0.87);
	-webkit-text-fill-color: rgb(0, 0, 0, 0.87);
}

.contact-phone-display > div::before {
	border: none;
	border-bottom-style: none !important;
}

.btn_line > hr.__se__solid,
.btn_line > hr.__se__dashed,
.btn_line > hr.__se__dotted {
	background-color: black;
}
